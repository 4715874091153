import { format } from 'date-fns'
import moment from 'moment'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// import 'firebase/compat/storage'
import ReduxSagaFirebase from 'redux-saga-firebase'
import { store } from '../redux'
import { setAuthenticated, setUser, setProvider } from '../redux/modules/authentication'
import { setMsg, setShowLoader, setInitialLoading } from '../redux/modules/application'
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getFunctions, httpsCallable } from 'firebase/functions';

const {
    REACT_APP_FIREBASE_PROJECT_ID,
    REACT_APP_FIREBASE_API_KEY,
    REACT_APP_FIREBASE_APP_ID,
    REACT_APP_FIREBASE_DATABASE_URL,
    REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    REACT_APP_MEASUREMENT_ID,
} = process.env

const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: `${REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: `${REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    measurementId: REACT_APP_MEASUREMENT_ID,
}

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig)
export const rsf         = new ReduxSagaFirebase(firebaseApp)
const functions          = getFunctions(firebaseApp);

const db         = firebase.firestore()
const testsDB    = db.collection('tests')
const usersDB    = db.collection('users')
const storage    = getStorage();

// Triggers when the auth state change for instance when the user signs-in or signs-out.
firebase.auth().onAuthStateChanged(async (user) => {
    // User is signed in
    if (user) {
        const isProvider = await findProvider({ email: user.email })
        if (isProvider) {
            store.dispatch(setAuthenticated(true))
            store.dispatch(setUser(user))
            store.dispatch(setProvider(isProvider[0]))
            store.dispatch(setShowLoader(false))
            store.dispatch(setInitialLoading(false))
        } else {
            console.log(user)
            usersDB.doc(user.email).update({ lastLogin: firebase.firestore.FieldValue.serverTimestamp() })
            store.dispatch(setAuthenticated(true))
            store.dispatch(setUser(user))
            store.dispatch(setShowLoader(false))
            store.dispatch(setInitialLoading(false))
        }
    } else {
        // User signed out
        store.dispatch(setAuthenticated(false))
        store.dispatch(setUser(null))
        store.dispatch(setShowLoader(false))
        store.dispatch(setInitialLoading(false))
    }
})

export const validatePatientID = async (email, id) => {
    try {
        const res = await httpsCallable(functions, 'isValidPatientID')({ email, id })
        return res.data
    } catch (e) {
        console.error(e)
        return null
    }
}

// Patients
export const findPatient = async (patientDetails) => {
    try {
        console.log(patientDetails)
        const res = await httpsCallable(functions, 'findPatient')(patientDetails)
        return res.data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const findPatients = async (query) => {
    try {
        const res = await httpsCallable(functions, 'findPatients')(query)
        console.log(res.data);
        return res.data
    } catch (e) {
        console.error(e)
        return null
    }
}

const fixPatientData = (data) => {
    // keep old date convert
    if (data?.dateOfBirth) {
        let dob = new Date(new Date(data.dateOfBirth).getTime() + new Date(data.dateOfBirth).getTimezoneOffset()*60*1000)
        data.dateOfBirth = format(new Date(dob), 'yyyy-MM-dd')
    }

    if (data?.symptomStartDate) {
        data.symptomStartDate = format(new Date(data.symptomStartDate), 'yyyy-MM-dd')
    }

    // do the date type transfer to ISO 8601
    if (data?.collectionDate) {
        data.collectionDate = moment(data.collectionDate).toISOString()
    }
}

export const addNewPatient = async (patientDetails) => {
    try {
        fixPatientData(patientDetails)
        const res = await httpsCallable(functions, 'addPatient')({ patientDetails })

        //Upload the Insurance Cards
        if( patientDetails.insurance_card_front !== null && typeof patientDetails.insurance_card_front !== "undefined" ) {
            const insurance_card_front_name = res.data.id + "_f." + patientDetails.insurance_card_front.name.split('.').pop();
            const insurance_card_front_ref = ref(storage, 'insurance_card/' + insurance_card_front_name);
            await uploadBytes(insurance_card_front_ref, patientDetails.insurance_card_back);
        }
        if( patientDetails.insurance_card_back !== null && typeof patientDetails.insurance_card_back !== "undefined" ) {
            const insurance_card_back_name = res.data.id + "_b." + patientDetails.insurance_card_back.name.split('.').pop();
            const insurance_card_back_ref = ref(storage, 'insurance_card/' + insurance_card_back_name);
            await uploadBytes(insurance_card_back_ref, patientDetails.insurance_card_front);
        }

        return res.data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const updatePatient = async (patientDetails) => {
    try {
        fixPatientData(patientDetails)
        const res = await httpsCallable(functions, 'updatePatient')(patientDetails)
        return res.data
    } catch (e) {
        console.error(e)
        return null
    }
}


export const checkDobAndEmail = async ( email, dob ) => {
    try {
        console.log(dob)
        const res = await httpsCallable(functions, 'isValidDOB')({ email, dob });
        if (res.data.error) {
            console.log("Maybe error?")
            // throw Error(res.data.error)
        }
        return res.data
    } catch (e) {
        console.log(e);
        console.log("Maybe error??????")
        // throw Error(e)

        // .then((result) => {
        //     const { data } = result
        //     resolve(data)
        // })
        // .catch((error) => {
        //     const { message } = error
        //     store.dispatch(setMsg({ text: "There is no test associated with this email", severity: 'error' }))
        //     store.dispatch(setShowLoader(false))
        //     reject()
        // })
    }
}

// Providers
export const addNewProvider = async (providerDetails) => {
    try {
        const res = await httpsCallable(functions, 'addProvider')({ providerDetails })
        if (res.data.error) {
            console.log("Maybe error?")
            // throw Error(res.data.error)
        }
        return res.data
    } catch (e) {
        console.log(e);
        console.log("Maybe error??????")
        // throw Error(e)
    }
}

export const findProvider = async (providerDetails) => {
    try {
        const res = await httpsCallable(functions, 'findProvider')(providerDetails)
        return res.data
    } catch (e) {
        // console.log("Error?")
        // console.error(e)
        return null
    }
}

export const uploadAuthProviders = async (providers) => {
    try {
        const res = await httpsCallable(functions, 'addAuthorizedProviders')(providers)
        return res.data
    } catch (e) {
        // console.log("Error?")
        // console.log(e)
        return null
    }
}

export const validateDOB = (email, dob) =>
    new Promise((resolve, reject) =>
        httpsCallable(functions, 'isValidDOB')({ email, dob })
        .then((result) => {
            const { data } = result
            resolve(data)
        })
        .catch((error) => {
            const { message } = error
            store.dispatch(setMsg({ text: "There is no test associated with this email", severity: 'error' }))
            store.dispatch(setShowLoader(false))
            reject()
        })
    )

export const createEmailAccount = (email, password) =>
    firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .catch((error) => {
            const { message } = error
            store.dispatch(setMsg({ text: message, severity: 'error' }))
        })

export const signInWithEmail = (email, password) =>
    firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch((error) => {
            const { message } = error
            store.dispatch(setMsg({ text: "Invalid Email or Password", severity: 'error' }))
            store.dispatch(setShowLoader(false))
        })

export const signOut = () =>
    firebase
        .auth()
        .signOut()
        .then(() => {
            store.dispatch(setShowLoader(false))
            store.dispatch(setProvider(null))
            store.dispatch(setUser(null))
        })
        .catch((error) => {
            const { message } = error
            store.dispatch(setMsg({ text: message, severity: 'error' }))
            store.dispatch(setShowLoader(false))
        })

export const getUserTests = async (email) => {
    // If test doesn't exist, possibly preventing all tests from being fetched
    async function getTest(id) {
        try {
            const doc = await testsDB.doc(id).get()
            return doc.data()
        } catch (error) {
            console.log('getTest: ', error)
            return null
        }
    }

    try {
        const snapshot = await usersDB.doc(email).collection('testIds').get()

        let testIds = []
        snapshot.forEach((doc) => {
            testIds.push(doc.id)
        })

        const testResults = await Promise.all(testIds.map(async (testId) => await getTest(testId)))

        return testResults[0] && testResults
    } catch (error) {
        console.log(error)
        store.dispatch(setMsg({ text: 'There was an error retrieving test results', severity: 'error' }))
    }
}

export const resetPassword = (email) =>
    firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
            // Password reset email sent.
            store.dispatch(setMsg({ text: 'Password reset email sent to: ' + email, severity: 'success' }))
            store.dispatch(setShowLoader(false))
        })
        .catch((error) => {
            // console.log(error)
            store.dispatch(setMsg({ text: 'This email does not exist in our system: ' + email, severity: 'warning' }))
            store.dispatch(setShowLoader(false))
        })
