import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, useWatch, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { map, some, isEmpty } from 'lodash'
import moment from 'moment'
import { store } from '../../../redux'
import { setMsg, setShowLoader, setInitialLoading } from '../../../redux/modules/application/actions'

import { addNewPatient } from '../../../services/firebase'

import 'date-fns'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import { Checkbox, Card, Select, MenuItem, Chip, Grid, Button, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Box, InputLabel, FormHelperText, TextField, Slider, ListItemText } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { Alert, AlertTitle, DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Collapse from '@mui/material/Collapse';

import { styles } from './styles'

import Symptoms from './Symptoms'
import WorkerDetails from './WorkerDetails'
import Release from './Release'
import Demographics from './Demographics'
import Complete from './Complete'

import { patientFormSchema, symptoms as allSymptoms } from './formSchema'
import { scrollToFieldByNameOrId } from '../../../utilities/helpers'

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import SwipeableViews from 'react-swipeable-views';

import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PanoramaIcon from '@mui/icons-material/Panorama';
import Check from '@mui/icons-material/Check';
import Autocomplete from '@mui/material/Autocomplete';

import insuranceList from "../../../listInsurance.json";

let UsaStates = require('usa-states').UsaStates;
var usStates = new UsaStates();
let stateList = usStates.states.map( state => state.name );

const steps = [
    'Intro',
    'Prerequisites',
    'Authorize',
    'About you',
    'Insurance',
];

const genderIdentities = [
    "Male", 
    "Female", 
    "Transgender Male", 
    "Transgender Female", 
    "Non-binary", 
    "Genderqueer", 
    "Two spirit", 
    "Agender",
    "Others",
    "Prefer not to identify", 
]

const NewPatientForm = () => {
    
    const dispatch = useDispatch()
    const { Application } = useSelector((state) => state)
    const { logoSrc } = Application

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${process.env.PUBLIC_URL}/portal-background.jpg)`,
            backgroundSize: 'cover',
            minHeight: "100vh",
            boxSizing: "border-box",
            width: "100%",
            paddingTop: "125px"
        },
        slider: {
            padding: "0 10px", 
            paddingRight: "13px",
            boxSizing: "border-box",
            width: "100%",
        },
        sliderCard: {
            padding: "15px", 
            maxWidth: "600px", 
            margin: "0 auto"
        },
        sliderContent: {
            maxHeight: "70vh",
            padding: "15px", 
            maxWidth: "600px", 
            margin: "0 auto",
            overflow: "auto"
        },
        sliderButtonWrapper: { 
            maxWidth: "600px", 
            margin: "0 auto" 
        },
        sliderButton: {
            color: "#ffffff", 
            marginTop: "15px"
        }
    }))
    const classes = useStyles()

    const [formComplete, setFormComplete] = React.useState(false)
    const [loading, setLoading]           = React.useState(false)
    const [demoForm, setDemoForm]         = React.useState(false)
    const [step, setStep]                 = React.useState(0)
    const [fillInsurance, setInsurance]   = React.useState(false)
    const [showWorker, setShowWorker]     = React.useState(false)
    const [workerType, setWorkerType]     = React.useState("")
    const [showSymptom, setShowSymptom]   = React.useState(false)
    const [haveSymptoms, setSymptoms]     = React.useState([])
    const [haveGIdentity, setGIdentity]   = React.useState([])
    const [assignSex, setAssignSex]       = React.useState("")
    const [personEthnicity, setEthnicity] = React.useState("")
    const [personRace, setRace]           = React.useState("")
    const [haveCardF, setCardF]           = React.useState(false)
    const [haveCardB, setCardB]           = React.useState(false)

    const {
        register,
        control,
        trigger,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: patientFormSchema.getDefaultFromShape(),
        resolver: yupResolver(patientFormSchema),
    })

    const symptoms = useWatch({ control, name: allSymptoms.map((field) => `symptoms.${field}`), defaultValue: {} })
    // const haveSymptoms = some(symptoms)

    const handleNextPage = async () => {
        const result = await trigger(['essentialWorker', 'essentialWorkerType', 'referred', 'symptoms', 'symptomStartDate', 'previouslyTested', 'release'])
        if (!result) {
            return
        }
        setDemoForm(true)
    }

    const handleInsuranceCard = async (card, file) => {
        if( card === "f" ) {
            setCardF(true)
            setValue('insurance_card_front', file)
        } else if( card === "b" ) {
            setCardB(true)
            setValue('insurance_card_back', file)
        }
    }

    const handleSubmit = async () => {

        // setValue("pronouns", haveGIdentity);
        setValue("genders", haveGIdentity);
        setValue("testingSite", "Nexsun Labs Onsite");

        const data = getValues()

        // let validate = await trigger()
        // if (!validate) {
        //     return false
        // }

        // setLoading(true)
        store.dispatch(setShowLoader(true))
        const res = await addNewPatient(data)
        if (res) {
            dispatch(setMsg({ text: 'Patient created successfully', severity: 'info' }))
            setStep( step + 1 );
        } else {
            dispatch(setMsg({ text: 'Error creating patient.', severity: 'error' }))
        }
        store.dispatch(setShowLoader(false))
        // setLoading(false)
        // setFormComplete(true)
        
}

    if (formComplete) {
        return <Complete logo={logoSrc} />
    }

    const SectionComplete = (
        <section style={{ display: "flex", flexDirection: "column" }}>
            <div>
                <Typography variant="h5">Your COVID19 patient registration has been completed successfully!</Typography>
            </div>
            <div style={{ padding: "15px 0" }}>
                <Typography variant="p" style={{ fontSize: '14px' }}>
                    If you haven’t done so already, please contact your local testing facility to schedule your COVID19 test. The completion of this registration does not guarantee a
                    COVID19 test. If you have any questions/concerns, please contact your local public health agency.
                </Typography>
            </div>
            <div style={{ paddingBottom: "15px" }}>
                <Typography variant="p" style={{ fontSize: '14px' }}>
                    If you have any questions/concerns, please contact your local public health agency.
                </Typography>
            </div>
            <div>
                <Typography variant="p" style={{ fontSize: '14px' }}>
                    We thank you for participating in the betterment of public health. Please stay safe and have a great day.
                </Typography>
            </div>
        </section>
    )

    const SectionIntro = (
        <section style={{ display: "flex", flexDirection: "column" }}>
            <div>
                <p>
                    Welcome to the patient registration portal for NEXSUN Lab’s COVID19 testing program.
                </p>
                <p style={{ borderLeft: "2px solid blue", width: "90%", padding: "0 10px", margin: "15px 0" }}>
                    Please fill out this form as accurately as possible to ensure
                    timely delivery of test results.
                </p>
                <p>
                    <strong>Information about COVID-19 testing:</strong> This test is used to check for COVID-19 virus in your body at this time. The test consists of a swab at the front of the
                    nose to collect bodily fluids. It is the most accurate FDA approved testing available to date. It does not tell you if you have had the COVID 19 infection in
                    the past, and the results may not be 100% correct.
                </p>
                <p>
                    Depending upon the results, follow-up medical recommendations may be provided. More information about this
                    COVID-19 test can be found on our <a href="https://portal-patient.com/">homepage</a>.
                </p>
            </div>
            <div style={{ textAlign: "Center", marginTop: "25px" }}>
                <Button onClick={() => nextStep()} variant="contained">Continue</Button>
            </div>
        </section>
    )

    const SectionAuthorize = (
        <section style={{ display: "flex", flexDirection: "column" }}>
            <div>
                <p>
                    I authorize Nexsun Labs to perform testing for COVID-19. Checking the box below acknowledges that I have been informed of the benefits and limitations of the test and that they have been explained by a qualified healthcare professional.
                </p>
                <p>
                    By checking the box below, I certify that I provided my unadulterated specimen to the collector to be analyzed; that the information I provided and, on the label affixed to the specimen is correct; and the specimen to be tested was sealed in my presence. I acknowledge that Nexsun Labs has my permission to release my results to the appropriate state and local health authorities. Your information and your test results are protected and kept confidential and will only be shared with the appropriate state and local health authorities as required by state law. If this sample is of a minor, this check box constitutes parental/guardian consent.
                </p>
                <p>
                    I understand that this test does NOT screen for the presence of antibodies IgG/IgM. Negative results may NOT rule out COVID-19 infection, and additional testing may be required. I understand that this test does not determine my level of immunity to COVID-19.
                </p>
                <p>
                    I understand that I am not entering into a doctor-patient relationship with Nexsun Labs or the ordering medical doctor, and that any questions or required follow up shall be my responsibility to arrange with my own physician.
                </p>
                <p>
                    I understand the information on this form, including that my test results will be provided to the appropriate state and local health authorities, and I voluntarily consent to have a COVID-19 nasal swab (or saliva) nucleic acid test.
                </p>
            </div>
            <div style={{ textAlign: "Center", marginTop: "25px" }}>
                <Button onClick={() => nextStep()} color="success" variant="contained">I agree</Button>
            </div>
        </section>
    )

    const SectionInsured = (
        <section style={{ display: "flex", flexDirection: "column" }}>
            <h3 style={{ textAlign: "center" }}>Insurance</h3>
            <Collapse in={fillInsurance} style={{ width: '100%' }}>
                <div style={{ padding: "5px" }}>
                    <Autocomplete
                        options={insuranceList}
                        autoHighlight
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Insurance Carrier"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            onChange={(e) => setValue('insurance_carrier', e.target.value )}
                            />
                        )}
                    />
                </div>
            </Collapse>
            <Collapse in={fillInsurance} style={{ width: '100%' }}>   
            <div style={{ padding: "5px" }}>
                <TextField
                    label="Insurance ID"
                    defaultValue=""
                    variant="filled"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setValue('insurance_id', e.target.value )}
                />
            </div>
            </Collapse>
            <Collapse in={fillInsurance} style={{ width: '100%' }}>
            <div style={{ padding: "5px" }}>
                <TextField
                    label="Insurance Group ID"
                    defaultValue=""
                    variant="filled"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <GroupIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setValue('insurance_member_id', e.target.value )}
                />
            </div>
            </Collapse>
            <Collapse in={fillInsurance} style={{ width: '100%' }}>
            <div style={{ padding: "5px" }}>
                <TextField
                    label="Insurance Subscriber ID"
                    defaultValue=""
                    variant="filled"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <BadgeIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setValue('insurance_subscriber_id', e.target.value )}
                />
            </div>
            </Collapse>
            <Collapse in={fillInsurance} style={{ width: '100%' }}>
            <h3 style={{ textAlign: "center" }}>Upload Insurance Card</h3>
                <Grid container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={6} p={1} direction="column" container justifyContent="center" alignItems="center">
                        <Grid direction="column" container justifyContent="center" alignItems="center">
                            <PanoramaIcon style={{ fontSize: "10em", opacity: haveCardF === true ? "0.9" : "0.5" }}/>
                            {/* <Check style={{ fontSize: "4em", position: "relative", bottom: 0, left: "-50px", color: "#45bf22" }}/> */}
                        </Grid>
                        <Grid>
                            <Button
                                variant="contained"
                                component="label"
                            >
                                Insurance Card (Front)
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => handleInsuranceCard("f", e.target.files[0])}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} p={1} direction="column" container justifyContent="center" alignItems="center">
                        <Grid ><PanoramaIcon style={{ fontSize: "10em", opacity: haveCardB === true ? "0.9" : "0.5" }}/></Grid>
                        <Grid>
                            <Button
                                variant="contained"
                                component="label"
                            >
                                Insurance Card (Back)
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => handleInsuranceCard("b", e.target.files[0])}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <hr/>
                <h3 style={{ textAlign: "center" }}>Insured Profile</h3>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Full Name of Insured"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        onChange={(e) => setValue('insurance_full_name', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Email of Insured"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setValue('insurance_email', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Address of Insured"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        name="address"
                        onChange={(e) => setValue('insurance_address', e.target.value )}
                    />
                </div>
                <Grid container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={6} p={1}>
                    <TextField
                        label="City"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        name="city"
                        onChange={(e) => setValue('insurance_city', e.target.value )}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} p={1}>
                        <Autocomplete
                            options={stateList}
                            autoHighlight
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="State"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    onChange={(e) => setValue('insurance_state', e.target.value )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Zip"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        onChange={(e) => setValue('insurance_zip', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <Autocomplete
                        options={["Self","Spouse","Child","Other"]}
                        autoHighlight
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Relationship to Insured"
                            onChange={(e) => setValue('insurance_relationship', e.target.value )}
                            />
                        )}
                    />
                </div>
            </Collapse>
            <Collapse in={!fillInsurance} style={{ width: '100%' }}>
                <div style={{ textAlign: "Center" }}>
                    <p>Would You like to fill out the Insurance Form?</p>
                    <div style={{ display: "flex", gap: "15px", alignItems: "center", justifyContent: "center" }}>
                        <Button onClick={() => confirmFillInsurance()} variant="contained" color="success">Yes</Button>
                        <Button onClick={() => checkInsured()} variant="contained" color="error">No</Button>
                    </div>
                </div>
            </Collapse>
            <Collapse in={fillInsurance} style={{ width: '100%' }}>
                <div style={{ textAlign: "Center", marginTop: "25px" }}>
                    <Button onClick={() => checkInsured()} variant="contained">Continue</Button>
                </div>
            </Collapse>
        </section>
    )

    const SectionAbout = (
        <section style={{ display: "flex", flexDirection: "column" }}>
            <h3 style={{ textAlign: "center" }}>About You</h3>
            <div style={{ padding: "5px" }}>
                <TextField
                    label="First Name"
                    defaultValue=""
                    variant="filled"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setValue('firstName', e.target.value )}
                />
            </div>
            <div style={{ padding: "5px" }}>
                <TextField
                    label="Last Name"
                    defaultValue=""
                    variant="filled"
                    fullWidth
                    onChange={(e) => setValue('lastName', e.target.value )}
                />
            </div>
            <div style={{ padding: "5px" }}>
                <TextField
                    label="Name you liked to be called"
                    defaultValue=""
                    variant="filled"
                    fullWidth
                    onChange={(e) => setValue('preferredName', e.target.value )}
                />
            </div>
            <div style={{ padding: "5px" }}>
                <Grid container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={6} p={1}>
                        <Controller
                            name="dateOfBirth"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { invalid } }) => {
                                return (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker 
                                            renderInput={(params) => <TextField style={{ width: "100%" }} {...params} />}
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline-dob"
                                            label="Date of Birth"
                                            openTo="year"
                                            disableFuture={true}
                                            autoOk={true}
                                            value={value}
                                            onChange={(date, value) => {
                                                try {
                                                    let dateValue = moment(date)
                                                    if (dateValue.isValid()) {
                                                        onChange(dateValue.toDate())
                                                    }
                                                } catch {}
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            invalid={invalid}
                                            TextFieldComponent={({ invalid, value, ...props }) => {
                                                return <TextField {...props} value={value ?? ''} error={invalid} helperText={invalid ? 'required' : null} />
                                            }}
                                        />
                                    </LocalizationProvider>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} p={1}>
                        <Select 
                            fullWidth
                            displayEmpty
                            value={assignSex}           
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em>Sex Assigned at Birth</em>;
                                }
                                return selected;
                            }}
                            onChange={(e) => setAssignSex(e.target.value)}
                        >
                            <MenuItem disabled value="">
                                <em>What sex were you assigned at Birth?</em>
                            </MenuItem>
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Intersex">Intersex</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </div>
            <div style={{ padding: "5px" }}>
                <Grid container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={12} p={1}>
                        <Select 
                            fullWidth
                            displayEmpty
                            multiple
                            value={haveGIdentity}           
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em>What is your gender identification?</em>;
                                }

                                return selected.join(', ');
                            }}
                            onChange={(e) => setGIdentity(      
                                typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                            )}
                        >
                            <MenuItem disabled value="">
                                <em>Gender Indentification</em>
                            </MenuItem>
                            {genderIdentities.map( identity => (
                                <MenuItem 
                                    key={identity} 
                                    value={identity}
                                >
                                    <Checkbox checked={haveGIdentity.indexOf(identity) > -1} />
                                    <ListItemText primary={identity}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </div>
            <div style={{ padding: "5px" }}>
                <Grid container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={6} p={1}>
                        <Select 
                            fullWidth
                            displayEmpty
                            value={personEthnicity}           
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em>Ethnicity</em>;
                                }
                                return selected;
                            }}
                            onChange={(e) => setEthnicity(e.target.value)}
                        >
                            <MenuItem value="Hispanic/Latino">Hispanic/Latino</MenuItem>
                            <MenuItem value="Non-Hispanic">Non-Hispanic</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} p={1}>
                        <Select 
                            fullWidth
                            displayEmpty
                            value={personRace}           
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em>Race</em>;
                                }
                                return selected;
                            }}
                            onChange={(e) => setRace(e.target.value)}
                        >
                            <MenuItem value="Native American">Native American</MenuItem>
                            <MenuItem value="American Indian">American Indian</MenuItem>
                            <MenuItem value="Black or African American">Black or African American</MenuItem>
                            <MenuItem value="White">White</MenuItem>
                            <MenuItem value="Native Hawaiian">Native Hawaiian</MenuItem>
                            <MenuItem value="Asian">Asian</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                            <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </div>
            <Grid style={{ borderBottom: "1px #ccc solid", marginBottom: "15px", padding: "15px", paddingTop: "0" }} container alignItems="center" direction="row" className={classes.formRow}>
                <Grid item xs={12} sm={8}>
                    Are you currently pregnant?
                </Grid>
                <Grid item xs={6} sm={4} alignItems="flex-end">
                    <Box display="flex" column justifyContent="flex-end">
                        <RadioGroup
                            row
                            name="essentialWorker"
                            onChange={(e) => setValue("pregnant", e.target.value)}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </Box>
                </Grid>
            </Grid>
            <div style={{ padding: "5px" }}>
                <TextField
                    label="Address"
                    name="address"
                    defaultValue=""
                    variant="filled"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <HomeIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setValue('address', e.target.value )}
                />
            </div>
            <Grid style={{ padding: "10px 0" }} container alignItems="center" direction="row" className={classes.formRow}>
                <Grid item xs={12} sm={6} p={1}>
                    <TextField
                        label="City"
                        name="city"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        onChange={(e) => setValue('city', e.target.value )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                    <Autocomplete
                        options={stateList}
                        autoHighlight
                        onChange={(e) => setValue('state', e.target.value )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="State"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <div style={{ padding: "5px" }}>
                <TextField
                    label="Zip"
                    defaultValue=""
                    variant="filled"
                    fullWidth
                    onChange={(e) => setValue('zip', e.target.value )}
                />
            </div>
            <div style={{ padding: "5px" }}>
                <TextField
                    label="Email"
                    defaultValue=""
                    variant="filled"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setValue('email', e.target.value )}
                />
            </div>
            <div style={{ padding: "5px" }}>
                <TextField
                    label="Phone"
                    defaultValue=""
                    variant="filled"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PhoneIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setValue('phone', e.target.value )}
                />
            </div>
            <Grid style={{ padding: "10px" }} container alignItems="center" direction="row" className={classes.formRow}>
                <Grid item xs={12} sm={4}>
                    Phone Type
                </Grid>
                <Grid item xs={12} sm={8} alignItems="flex-end">
                    <Box display="flex" column justifyContent="flex-end">
                        <RadioGroup
                            row
                            name="phoneType"
                            onChange={(e) => setValue('phoneType', e.target.value )}
                        >
                            <FormControlLabel value={"home"} control={<Radio />} label="Home" />
                            <FormControlLabel value={"mobile"} control={<Radio />} label="Mobile" />
                            <FormControlLabel value={"work"} control={<Radio />} label="Work" />
                        </RadioGroup>
                    </Box>
                </Grid>
            </Grid>
            <Grid style={{ padding: "10px" }} container alignItems="center" direction="row" className={classes.formRow}>
                <Grid item xs={12} sm={8}>
                    May we text?
                </Grid>
                <Grid item xs={6} sm={4} alignItems="flex-end">
                    <Box display="flex" column justifyContent="flex-end">
                        <RadioGroup
                            row
                            name="mayWeText"
                            onChange={(e) => setValue('mayWeText', e.target.value )}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </Box>
                </Grid>
            </Grid>
            <div style={{ textAlign: "Center", marginTop: "25px" }}>
                <Button onClick={() => checkAbout()} variant="contained">Continue</Button>
            </div>
        </section>
    )
    
    const typeOfSymptoms = [
        "Cough",
        "Chills",
        "Shortness of Breath",
        "Sore Throat",
        "Body Aches",
        "Diarrhea",
        "Vomiting",
        "Fever",
        "Headache",
        "Runny Nose",
        "Increase Fatigue",
        "Lack of Taste/Smell",
        "Abdominal Pain",
        "Others"
    ];
    
    const setEssentialWorker = (e) => {
        setValue('essentialWorker', e.target.value === "true" ? true : false )
        setShowWorker(e.target.value === "true" ? true : false)
    } 

    const setEssentiaWorkerlType = (e) => {
        setValue('essentialWorkerType', e.target.value )
        setWorkerType(e.target.value)
    } 

    const updateSymptom = (e) => {
        setValue('haveSymptoms', e.target.value === "true" ? true : false )
        setShowSymptom(e.target.value === "true" ? true : false)
    } 

    const updateSymptoms = (e) => {
        setSymptoms(      
            typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
        );
    }

    const SectionPrerequisite = (
        <div style={{ display: "flex", flexDirection: "column"}}>
            <Collapse in={true} style={{ width: '100%' }}>
                <Grid id="essentialWorker" container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={8}>
                        Are you an essential worker?
                    </Grid>
                    <Grid item xs={6} sm={4} alignItems="flex-end">
                        <Box display="flex" column justifyContent="flex-end">
                            <RadioGroup
                                row
                                name="essentialWorker"
                                onChange={(e) => setEssentialWorker(e)}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Box>
                    </Grid>
                </Grid>
            </Collapse >
            <Collapse in={showWorker}>
                <Select 
                    fullWidth
                    displayEmpty
                    value={workerType}           
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <em>What type of essential worker?</em>;
                        }
                        return selected;
                    }}
                    onChange={(e) => setEssentiaWorkerlType(e)}
                >
                    <MenuItem disabled value="">
                        <em>What type of essential worker?</em>
                    </MenuItem>
                    <MenuItem value="First Responder">First Responder</MenuItem>
                    <MenuItem value="Health Care Worker">Health Care Worker</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                </Select>
            </Collapse>
            <Collapse in={true} style={{ width: '100%' }}>
                <Grid id="referred" container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={8}>
                        Referred by State or Municipal Health Official?
                    </Grid>
                    <Grid item xs={6} sm={4} alignItems="flex-end">
                        <Box display="flex" column justifyContent="flex-end">
                            <RadioGroup
                                row
                                name="referred"
                                onChange={(e) => setValue("referred", e.target.value)}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Box>
                    </Grid>
                </Grid>
            </Collapse >
            <Collapse in={true}>
                <Grid id="symptoms" container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={8}>
                        Any symptoms in the past week?
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Box display="flex" justifyContent="flex-end">
                            <RadioGroup
                                row
                                name="symptoms"
                                onChange={(e) => updateSymptom(e)}
                                defaultValue={false}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Box>
                    </Grid>
                </Grid>
            </Collapse>
            <Collapse in={showSymptom}>
                <Grid id="symptom_types" container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Select 
                                fullWidth
                                displayEmpty
                                multiple
                                value={haveSymptoms}           
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em>What symptoms have you experienced?</em>;
                                    }

                                    return selected.join(', ');
                                }}
                                onChange={updateSymptoms}
                            >
                                <MenuItem disabled value="">
                                    <em>What symptoms have you experienced?</em>
                                </MenuItem>
                                {typeOfSymptoms.map( symptom => (
                                    <MenuItem 
                                        key={symptom} 
                                        value={symptom}
                                    >
                                        <Checkbox checked={haveSymptoms.indexOf(symptom) > -1} />
                                        <ListItemText primary={symptom}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" style={{ padding: "5px 0", paddingTop: "10px" }}>
                            <Grid id="symptoms" container alignItems="center" direction="row" className={classes.formRow}>
                                <Grid item xs={12} sm={8}>
                                    Last date remembered having symptom
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <Controller
                                        name="symptomStartDate"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { invalid } }) => {
                                            return (
                                                <Box display="flex" flexDirection="row" alignItems="baseline">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            renderInput={(params) => <TextField {...params} />}
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="date-picker-date-symptoms-begin"
                                                            autoOk={true}
                                                            value={value}
                                                            onChange={(date) => {
                                                                    onChange(date)
                                                                
                                                            }}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            invalid={invalid}
                                                            TextFieldComponent={({ invalid, value, ...props }) => {
                                                                return <TextField name="symptomStartDate" {...props} value={value ?? ''} error={invalid} />
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </Box>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Collapse>
            <Collapse in={true}>
                <Grid container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={8}>
                        Did you previously have a COVID-19 test?
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Box display="flex" justifyContent="flex-end">
                            <RadioGroup
                                row
                                name="past_covid_test"
                                onChange={(e) => setValue("previouslyTested", e.target.value)}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Box>
                    </Grid>
                </Grid>
            </Collapse>
            <div style={{ textAlign: "Center", marginTop: "25px" }}>
                <Button onClick={() => checkPrerequisite()} variant="contained">Continue</Button>
            </div>
        </div>
    )    

    const SliderButton = (
        <div className={classes.sliderButtonWrapper}>
            <Button className={classes.sliderButton} style={{ color: "#fff" }} onClick={() => previousStep()}>← Back</Button>
        </div>
    );
    
    
    const checkPrerequisite = () => {
        const data = getValues()

        if( data.essentialWorker === null) {
            alert("Please indicate if you are an essential worker")
            return;
        } else if( data.essentialWorker === true ) {
            if( data.essentialWorkerType === null) {
                alert("Please indicate what essential worker you are")
                return;
            }
        }

        if( data.referred === null) {
            alert("Please say if you were referred or not")
            return;
        }      

        if( data.previouslyTested === null) {
            alert("Please indicate if you have previously tested for COVID-19")
            return;
        }        

        if( data.haveSymptoms === true ) {
            if( haveSymptoms.length === 0 ) {
                alert("Please indicate one symptom you may have")
                return;
            }
            if( data.symptomStartDate === null ) {
                alert("Please tell us a date when you started feeling symptom/s")
                return;
            }
        }
        
        setStep( step + 1 );
    }

    const confirmFillInsurance = async () => {
        setInsurance( true );
    }

    const checkInsured = async () => {
        // const data = getValues()
        // console.log(data)
        // const res = await addNewPatient(data)
        handleSubmit()
    }

    const checkAbout = () => {
        const data = getValues()

        if( data.firstName === null) {
            alert("Please enter your first name")
            return;
        }     

        if( data.lastName === null) {
            alert("Please enter your first name")
            return;
        }   

        if( data.dateOfBirth === null) {
            alert("Please enter your DOB")
            return;
        }      

        if( data.sexAssignedAtBirth === null) {
            if( assignSex.length === 0 ) {
                alert("Please input your assigned Sex at birth")
                return;
            } else {
                setValue("sexAssignedAtBirth", assignSex);
            }
        }      
        
        if( personEthnicity.length > 0 ) {
            setValue("ethnicity", personRace);
        } else {
            setValue("ethnicity", "Prefer not to say");
        }

        if( personRace.length > 0 ) {
            setValue("race", personRace);
        } else {
            setValue("race", "Prefer not to say");
        }


        if( data.address == null || 
            data.city == null ||
            data.zip == null ||
            data.state == null
            )
            {
                if( data.state == null ) {
                    alert("Please select what state you are from")
                    return;
                }

                if( data.city == null ) {
                    alert("Please tel us what city you are from")
                    return;
                }

                alert("Please fill out all address fields")
                return;
            }

        if( data.email === null) {
            alert("An email is required")
            return;
        }  

        if( data.phone === null) {
            alert("A phone number is required to contact for results")
            return;
        }  

        setStep( step + 1 );
    }

    const stepIsStateWorker = (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ textAlign: "center", paddingBottom: "25px" }}>Have been referred by a State or Municipal Health Official?</div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                <Button variant="contained" color="success">Yes</Button>
                <Button variant="contained" color="error">No</Button>
            </div>
        </div>
    )

    let stepForm = null;
    switch( step ) {
        case 1: stepForm = SectionIntro; break;
        case 2: stepForm = SectionPrerequisite; break;
        case 3: stepForm = SectionAuthorize; break;
        case 3: stepForm = SectionAbout; break;
        default: stepForm = SectionIntro;
    }

    const nextStep = () => {
        setStep( step + 1 );
    }

    const previousStep = () => {
        setStep( step - 1 );
    }

    return (
        <div className={classes.root}>
            <div style={{ width: "100%" }} >
                <div style={{ boxSizing: "border-box", position: "absolute", top: 0, left: 0, textAlign: "center", background: "#fff", width: "100%", padding: "10px" }}>
                    <img src={logoSrc} style={{ width: "300px", margin: "0 auto" }} className="main-logo main-logo-top" alt="Nexsun Diagnostic Laboratories" />
                </div>
                <div style={{ boxSizing: "border-box", position: "absolute", top: 67, left: 0, textAlign: "center", background: "#fff", width: "100%", padding: "10px" }}>
                    <Stepper activeStep={step} alternativeLabel>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel></StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </div>
                <SwipeableViews style={{width: "100%"}} index={step} disabled={true}>
                    <div className={classes.slider}>
                        <Card className={classes.sliderCard}>
                            <div className={classes.sliderContent}>
                                {SectionIntro}
                                {/* {SectionInsured} */}
                            </div>
                        </Card>
                    </div>
                    <div className={classes.slider}>
                        <Card className={classes.sliderCard}>
                            <div className={classes.sliderContent}>
                                {SectionPrerequisite}
                            </div>
                        </Card>
                        {SliderButton}             
                    </div>
                    <div className={classes.slider}>
                        <Card className={classes.sliderCard}>
                            <div className={classes.sliderContent}>
                                {SectionAuthorize}
                            </div>
                        </Card>
                        {SliderButton}                
                    </div>
                    <div className={classes.slider}>
                        <Card className={classes.sliderCard}>
                            <div className={classes.sliderContent}>
                                {SectionAbout}
                            </div>
                        </Card>
                        {SliderButton}
                    </div>
                    <div className={classes.slider}>
                        <Card className={classes.sliderCard}>
                            <div className={classes.sliderContent}>
                                {SectionInsured}
                            </div>
                        </Card>
                        {SliderButton}
                    </div>
                    <div className={classes.slider}>
                        <Card className={classes.sliderCard}>
                            <div className={classes.sliderContent}>
                                {SectionComplete}
                            </div>
                        </Card>
                    </div>
                </SwipeableViews>
            </div>
        </div>
    )
    // return stepForm;

    return demoForm ? (
        <Demographics loading={loading} handleSubmit={handleSubmit} form={{ control, errors, setValue }} />
    ) : (
        <div className={classes.formContainer}>
            {/* <img src={logoSrc} className="main-logo" alt="Nexsun Diagnostic Laboratories" style={{ margin: 'auto', marginBottom: '5%' }} /> */}

            <WorkerDetails form={{ control, errors, setValue }} register={register} handleSubmit={handleSubmit} />

            <Symptoms form={{ control, errors, setValue }} />


            <div className={classes.item}>
                <Controller
                    name="previouslyTested"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { invalid } }) => {
                        return (
                            <FormControl error={invalid} style={{ width: '100%' }}>
                                <Grid id="previouslyTested" container alignItems="center" direction="row" className={classes.formRow}>
                                    <Grid item xs={12} sm={8}>
                                        <InputLabel className="formRadioLabel">Have you had a COVID-19 test previously?</InputLabel>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <RadioGroup onChange={(evt) => onChange(evt.target.value === 'true')} value={value} row>
                                                <FormControlLabel value={true} control={<Radio name="previouslyTested" />} label="Yes" />
                                                <FormControlLabel value={false} control={<Radio name="previouslyTested" />} label="No" />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={1}>
                                        <FormHelperText>{invalid ? 'Required' : ''}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        )
                    }}
                />
            </div>

            <Release form={{ control, errors, setValue }} />

            {!isEmpty(errors) ? (
                <Alert severity="warning">
                    <AlertTitle>Please indicate:</AlertTitle>
                    <Box display="flex" flexWrap="wrap">
                        {map(errors, ({ message }, field) => (
                            <Chip
                                key={field}
                                style={{ margin: '5px 12px' }}
                                label={message}
                                onClick={() => {
                                    scrollToFieldByNameOrId(field)
                                }}
                            />
                        ))}
                    </Box>
                </Alert>
            ) : null}

            <Button variant="contained" color="primary" style={{ width: '25%', margin: 'auto', marginTop: '5%' }} onClick={handleNextPage}>
                Next
            </Button>
        </div>
    )
}

export default NewPatientForm
