import React from 'react'
import { useSelector } from 'react-redux'
import { map, some, isEmpty } from 'lodash'
import moment from 'moment'
import { useForm, useWatch, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Checkbox, Card, Select, MenuItem, Chip, Grid, Button, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Box, InputLabel, FormHelperText, TextField, Slider, ListItemText, backdropClasses } from '@mui/material'
import { Alert, AlertTitle } from '@mui/lab'

import Collapse from '@mui/material/Collapse';

import { DatePicker, LocalizationProvider  } from '@mui/lab'
import DateFnsUtils from '@date-io/date-fns'
import { addDays } from 'date-fns'
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { styles } from './styles'

import { makeStyles } from "@mui/styles"

import WorkerDetails from '../Form/WorkerDetails'
import Demographics from '../Form/Demographics'
import Symptoms from '../Form/Symptoms'
import Release from '../Form/Release'

import insuranceList from "../../../listInsurance.json";

import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PanoramaIcon from '@mui/icons-material/Panorama';
import Check from '@mui/icons-material/Check';
import Autocomplete from '@mui/material/Autocomplete';

import { providerPatientFormSchema, symptoms as allSymptoms } from '../Form/formSchema'

let UsaStates = require('usa-states').UsaStates;
var usStates = new UsaStates();
const stateList = usStates.states.map( state => state.name );

const ViewPatient = (props) => {

    const { patient, newPatient, loading, handleSubmit, back } = props

    const onSubmit = async () => {
        const data = getValues()

        // setValue("pronouns", haveGIdentity);
        setValue("genders", haveGIdentity);
        setValue("testingSite", "Nexsun Labs Onsite");

        await handleSubmit(data)
    }

    const {
        control,
        trigger,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: patient ? patient : providerPatientFormSchema.getDefaultFromShape(),
        resolver: yupResolver(providerPatientFormSchema),
    })

    let hasSymptomCheck = Object.values(patient.symptoms).some(val => val === true);
    hasSymptomCheck = patient.haveSymptoms ?? false;

    let patientRaces = Object.keys(patient.races).filter( race => patient.races[race] )

    let genderCheck = Array.isArray(patient.genders) ? patient.genders : [];
    
    const [formComplete, setFormComplete] = React.useState(true)
    // const [loading, setLoading]        = React.useState(false)
    const [showWorker, setShowWorker]     = React.useState(patient.essentialWorker === true ?? false)
    const [workerType, setWorkerType]     = React.useState(patient.essentialWorkerType ?? "")
    const [showSymptom, setShowSymptom]   = React.useState(hasSymptomCheck)
    const [haveSymptoms, setSymptoms]     = React.useState([])
    const [haveGIdentity, setGIdentity]   = React.useState(genderCheck)
    const [assignSex, setAssignSex]       = React.useState(patient.sexAssignedAtBirth)
    const [personEthnicity, setEthnicity] = React.useState(patient.ethnicity)
    const [personRace, setRace]           = React.useState(patientRaces[0])
    const [haveCardF, setCardF]           = React.useState(false)
    const [haveCardB, setCardB]           = React.useState(false)

    const typeOfSymptoms = [
        "Cough",
        "Chills",
        "Shortness of Breath",
        "Sore Throat",
        "Body Aches",
        "Diarrhea",
        "Vomiting",
        "Fever",
        "Headache",
        "Runny Nose",
        "Increase Fatigue",
        "Lack of Taste/Smell",
        "Abdominal Pain",
        "Others"
    ];

    const genderIdentities = [
        "Male", 
        "Female", 
        "Transgender Male", 
        "Transgender Female", 
        "Non-binary", 
        "Genderqueer", 
        "Two spirit", 
        "Agender",
        "Others",
        "Prefer not to identify", 
    ]

    const setEssentialWorker = (e) => {
        setValue('essentialWorker', e.target.value === "true" ? true : false )
        setShowWorker(e.target.value === "true" ? true : false)
    } 

    const setEssentiaWorkerlType = (e) => {
        setValue('essentialWorkerType', e.target.value )
        setWorkerType(e.target.value)
    } 

    const updateSymptom = (e) => {
        setValue('haveSymptoms', e.target.value === "true" ? true : false )
        setShowSymptom(e.target.value === "true" ? true : false)
    } 

    const updateSymptoms = (e) => {
        setSymptoms(      
            typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
        );
    }

    const handleInsuranceCard = async (card, file) => {
        if( card === "f" ) {
            setCardF(true)
            setValue('insurance_card_front', file)
        } else if( card === "b" ) {
            setCardB(true)
            setValue('insurance_card_back', file)
        }
    }

    const previousStep = () => {
        back();
    }

    const symptoms = useWatch({ control, name: allSymptoms.map((field) => `symptoms.${field}`), defaultValue: {} })
    // const haveSymptoms = some(symptoms)

    const {
        Application,
        Authentication: { provider },
    } = useSelector((state) => state)
    const { logoSrc } = Application

    const useStyles = makeStyles(styles)
    const classes = useStyles()
    console.log(patient);
    return (
        <div className={classes.viewPatient}>
            <div style={{maxWidth: "560px", width: "100%"}}><Button className={classes.sliderButton} style={{ textAlign: "left" }} onClick={() => previousStep()}>← Back</Button></div>
            <img src={logoSrc} className="main-logo" alt="Nexsun Diagnostic Laboratories" style={{ width: '400px', margin: 'auto', marginBottom: '5%' }} />

            <Typography variant="h4" style={{ marginBottom: '15px' }}>
                Patient Data
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '15px' }}>
                Please review the patient data below and make any necessary changes.
            </Typography>

            <div style={{ display: "flex", flexDirection: "column", paddingBottom: "15px", maxWidth: "500px", width: "100%"}}>
                <div className={classes.item}>
                    <Select 
                        fullWidth
                        displayEmpty
                        defaultValue={patient.testingSite ?? "Nexsun Labs Onsite"}
                        onChange={(e) => setValue("testingSite", e.target.value)}
                    >
                        {(provider?.location ?? '').split(',').map((site) => (
                            <MenuItem key={site} value={site}>
                                {site}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                {provider ? (
                    <Controller
                        name="collectionDate"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { invalid } }) => {
                            return (
                                <Grid container alignItems="center" direction="row" className={classes.formRow}>
                                    <Grid item xs={12} sm={6}>
                                        Sample Collection Date
                                    </Grid>
                                    <Grid item xs={6} sm={6} style={{ display: "flex", justifyContent: "end", paddingTop: "15px" }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                fullWidth
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                name=""
                                                id="date-picker-collection-date"
                                                autoOk={true}
                                                value={value}
                                                onChange={(date, value) => {
                                                    try {
                                                        let dateValue = moment(date)
                                                        if (dateValue.isValid()) {
                                                            onChange(dateValue.toDate())
                                                        }
                                                    } catch {}
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalid={invalid}
                                                renderInput={({ invalid, value, ...props }) => {
                                                    return <TextField {...props} value={value ?? ''} error={invalid} />
                                                }}
                                            />
                                        </LocalizationProvider >
                                    </Grid>
                                </Grid>
                            )
                        }}
                    />
                ) : null}
            </div>

            <div style={{ display: "flex", flexDirection: "column", maxWidth: "500px", paddingBottom: "15px"}}>
                <Collapse in={true} style={{ width: '100%' }}>
                    <Grid id="essentialWorker" container alignItems="center" direction="row" className={classes.formRow}>
                        <Grid item xs={12} sm={8}>
                            Are you an essential worker?
                        </Grid>
                        <Grid item xs={6} sm={4} alignItems="flex-end">
                            <Box display="flex" column justifyContent="flex-end">
                                <RadioGroup
                                    row
                                    defaultValue={patient.essentialWorker ?? false}
                                    name="essentialWorker"
                                    onChange={(e) => setEssentialWorker(e)}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                    </Grid>
                </Collapse >
                <Collapse in={showWorker}>
                    <Select 
                        fullWidth
                        displayEmpty
                        value={workerType}           
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em>What type of essential worker?</em>;
                            }
                            return selected;
                        }}
                        onChange={(e) => setEssentiaWorkerlType(e)}
                    >
                        <MenuItem disabled value="">
                            <em>What type of essential worker?</em>
                        </MenuItem>
                        <MenuItem value="First Responder">First Responder</MenuItem>
                        <MenuItem value="Health Care Worker">Health Care Worker</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                </Collapse>
                <Collapse in={true} style={{ width: '100%' }}>
                    <Grid id="referred" container alignItems="center" direction="row" className={classes.formRow}>
                        <Grid item xs={12} sm={8}>
                            Referred by State or Municipal Health Official?
                        </Grid>
                        <Grid item xs={6} sm={4} alignItems="flex-end">
                            <Box display="flex" column justifyContent="flex-end">
                                <RadioGroup
                                    row
                                    name="referred"
                                    defaultValue={patient.referred ?? false}
                                    onChange={(e) => setValue("referred", e.target.value)}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                    </Grid>
                </Collapse >
                <Collapse in={true}>
                    <Grid id="symptoms" container alignItems="center" direction="row" className={classes.formRow}>
                        <Grid item xs={12} sm={8}>
                            Any symptoms in the past week?
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Box display="flex" justifyContent="flex-end">
                                <RadioGroup
                                    row
                                    name="symptoms"
                                    onChange={(e) => updateSymptom(e)}
                                    value={showSymptom}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                    </Grid>
                </Collapse>
                <Collapse in={showSymptom}>
                    <Grid id="symptom_types" container alignItems="center" direction="row" className={classes.formRow}>
                        <Grid item xs={12} sm={12}>
                            <Box display="flex" justifyContent="flex-end">
                                <Select 
                                    fullWidth
                                    displayEmpty
                                    multiple
                                    value={haveSymptoms}           
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <em>What symptoms have you experienced?</em>;
                                        }

                                        return selected.join(', ');
                                    }}
                                    onChange={updateSymptoms}
                                >
                                    <MenuItem disabled value="">
                                        <em>What symptoms have you experienced?</em>
                                    </MenuItem>
                                    {typeOfSymptoms.map( symptom => (
                                        <MenuItem 
                                            key={symptom} 
                                            value={symptom}
                                        >
                                            <Checkbox checked={haveSymptoms.indexOf(symptom) > -1} />
                                            <ListItemText primary={symptom}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box display="flex" justifyContent="flex-end" style={{ padding: "5px 0", paddingTop: "10px" }}>
                                <Grid id="symptoms" container alignItems="center" direction="row" className={classes.formRow}>
                                    <Grid item xs={12} sm={8}>
                                        Last date remembered having symptom
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <Controller
                                            name="symptomStartDate"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { invalid } }) => {
                                                return (
                                                    <Box display="flex" flexDirection="row" alignItems="baseline">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                renderInput={(params) => <TextField {...params} />}
                                                                disableToolbar
                                                                variant="inline"
                                                                format="MM/dd/yyyy"
                                                                margin="normal"
                                                                id="date-picker-date-symptoms-begin"
                                                                autoOk={true}
                                                                value={value}
                                                                onChange={(date) => {
                                                                        onChange(date)
                                                                    
                                                                }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                                invalid={invalid}
                                                                TextFieldComponent={({ invalid, value, ...props }) => {
                                                                    return <TextField name="symptomStartDate" {...props} value={value ?? ''} error={invalid} />
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Collapse>
                <Collapse in={true}>
                    <Grid container alignItems="center" direction="row" className={classes.formRow}>
                        <Grid item xs={12} sm={8}>
                            Did you previously have a COVID-19 test?
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Box display="flex" justifyContent="flex-end">
                                <RadioGroup
                                    row
                                    name="past_covid_test"
                                    onChange={(e) => setValue("previouslyTested", e.target.value)}
                                    defaultValue={patient.previouslyTested ?? false}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </Box>
                        </Grid>
                    </Grid>
                </Collapse>
            </div>

            <section style={{ display: "flex", flexDirection: "column", maxWidth: "515px", width: "100%" }}>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="First Name"
                        variant="filled"
                        fullWidth
                        defaultValue={patient.firstName ?? ""}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setValue('firstName', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Last Name"
                        defaultValue={patient.lastName ?? ""}
                        variant="filled"
                        fullWidth
                        onChange={(e) => setValue('lastName', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Name you liked to be called"
                        defaultValue={patient.preferredName ?? ""}
                        variant="filled"
                        fullWidth
                        onChange={(e) => setValue('preferredName', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px", marginTop: "10px" }}>
                    <Grid container alignItems="center" direction="row" className={classes.formRow}>
                        <Grid item xs={12} sm={6} p={1}>
                            <Controller
                                name="dateOfBirth"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { invalid } }) => {
                                    var newDate = new Date(new Date(value).getTime() + new Date(value).getTimezoneOffset()*60*1000);
                                    console.log(newDate);
                                    return (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker 
                                                renderInput={(params) => <TextField style={{ width: "100%" }} {...params} />}
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date-picker-inline-dob"
                                                label="Date of Birth"
                                                openTo="year"
                                                disableFuture={true}
                                                autoOk={true}
                                                value={newDate}
                                                onChange={(date, value) => {
                                                    try {
                                                        let dateValue = moment(date)
                                                        if (dateValue.isValid()) {
                                                            onChange(dateValue.toDate())
                                                        }
                                                    } catch {}
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalid={invalid}
                                                TextFieldComponent={({ invalid, value, ...props }) => {
                                                    return <TextField {...props} value={value ?? ''} error={invalid} helperText={invalid ? 'required' : null} />
                                                }}
                                            />
                                        </LocalizationProvider>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} p={1}>
                            <Select 
                                fullWidth
                                displayEmpty
                                value={assignSex}           
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em>Sex Assigned at Birth</em>;
                                    }
                                    return selected;
                                }}
                                onChange={(e) => setAssignSex(e.target.value)}
                            >
                                <MenuItem disabled value="">
                                    <em>What sex were you assigned at Birth?</em>
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Intersex">Intersex</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ padding: "5px" }}>
                    <Grid container alignItems="center" direction="row" className={classes.formRow}>
                        <Grid item xs={12} sm={12} p={1}>
                            <Select 
                                fullWidth
                                displayEmpty
                                multiple
                                value={haveGIdentity}           
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em>What is your gender identification?</em>;
                                    }

                                    return selected.join(', ');
                                }}
                                onChange={(e) => setGIdentity(      
                                    typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                                )}
                            >
                                <MenuItem disabled value="">
                                    <em>Gender Indentification</em>
                                </MenuItem>
                                {genderIdentities.map( identity => (
                                    <MenuItem 
                                        key={identity} 
                                        value={identity}
                                    >
                                        <Checkbox checked={haveGIdentity.indexOf(identity) > -1} />
                                        <ListItemText primary={identity}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ padding: "5px" }}>
                    <Grid container alignItems="center" direction="row" className={classes.formRow}>
                        <Grid item xs={12} sm={6} p={1}>
                            <Select 
                                fullWidth
                                displayEmpty
                                value={personEthnicity}           
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em>Ethnicity</em>;
                                    }
                                    return selected;
                                }}
                                onChange={(e) => setEthnicity(e.target.value)}
                            >
                                <MenuItem value="Hispanic/Latino">Hispanic/Latino</MenuItem>
                                <MenuItem value="Non-Hispanic">Non-Hispanic</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} p={1}>
                            <Select 
                                fullWidth
                                displayEmpty
                                value={personRace}           
                                renderValue={(selected) => {
                                    if( typeof selected !== "undefined" ) {
                                        if (selected.length === 0) {
                                            return <em>Race</em>;
                                        }
                                        return selected;
                                    }
                                }}
                                onChange={(e) => setRace(e.target.value)}
                            >
                                <MenuItem value="Native American">Native American</MenuItem>
                                <MenuItem value="American Indian">American Indian</MenuItem>
                                <MenuItem value="Black or African American">Black or African American</MenuItem>
                                <MenuItem value="White">White</MenuItem>
                                <MenuItem value="Native Hawaiian">Native Hawaiian</MenuItem>
                                <MenuItem value="Asian">Asian</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                                <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </div>
                <Grid style={{ borderBottom: "1px #ccc solid", marginBottom: "15px", padding: "15px", paddingTop: "0" }} container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={8}>
                        Are you currently pregnant?
                    </Grid>
                    <Grid item xs={6} sm={4} alignItems="flex-end">
                        <Box display="flex" column justifyContent="flex-end">
                            <RadioGroup
                                row
                                name="pregant"
                                defaultValue={patient.pregnant ?? false}
                                onChange={(e) => setValue("pregnant", e.target.value)}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Box>
                    </Grid>
                </Grid>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Address"
                        name="address"
                        defaultValue={patient.address}
                        variant="filled"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setValue('address', e.target.value )}
                    />
                </div>
                <Grid style={{ padding: "10px 0" }} container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={6} p={1}>
                        <TextField
                            label="City"
                            name="city"
                            defaultValue={patient.city}
                            variant="filled"
                            fullWidth
                            onChange={(e) => setValue('city', e.target.value )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} p={1}>
                        <Autocomplete
                            options={stateList}
                            autoHighlight
                            defaultValue={patient.state ?? ""}
                            onChange={(e) => setValue('state', e.target.value )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="State"
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Zip"
                        variant="filled"
                        defaultValue={patient.zip}
                        fullWidth
                        onChange={(e) => setValue('zip', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Email"
                        defaultValue={patient.email}
                        variant="filled"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setValue('email', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Phone"
                        defaultValue={patient.phone}
                        variant="filled"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PhoneIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setValue('phone', e.target.value )}
                    />
                </div>
                <Grid style={{ padding: "10px" }} container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={4}>
                        Phone Type
                    </Grid>
                    <Grid item xs={12} sm={8} alignItems="flex-end">
                        <Box display="flex" column justifyContent="flex-end">
                            <RadioGroup
                                row
                                name="phoneType"
                                defaultValue={patient.phoneType}
                                onChange={(e) => setValue('phoneType', e.target.value )}
                            >
                                <FormControlLabel value={"home"} control={<Radio />} label="Home" />
                                <FormControlLabel value={"mobile"} control={<Radio />} label="Mobile" />
                                <FormControlLabel value={"work"} control={<Radio />} label="Work" />
                            </RadioGroup>
                        </Box>
                    </Grid>
                </Grid>
                <Grid style={{ padding: "10px" }} container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={8}>
                        May we text?
                    </Grid>
                    <Grid item xs={6} sm={4} alignItems="flex-end">
                        <Box display="flex" column justifyContent="flex-end">
                            <RadioGroup
                                row
                                name="mayWeText"
                                defaultValue={patient.mayWeText}
                                onChange={(e) => setValue('mayWeText', e.target.value )}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Box>
                    </Grid>
                </Grid>
            </section>


            {newPatient ? (
                <div className={classes.section} style={{ maxWidth: '1000px', marginBottom: '0' }}>
                    <Release form={{ control, errors, setValue }} />
                </div>
            ) : null}

            {/* <Demographics loading={loading} handleSubmit={onSubmit} form={{ control, errors, setValue }} /> */}
 
            <section style={{ display: "flex", flexDirection: "column" }}>
                <h3 style={{ textAlign: "center" }}>Insurance</h3>
                <div style={{ padding: "5px" }}>
                    <Autocomplete
                        options={insuranceList}
                        autoHighlight
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Insurance Carrier"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            onChange={(e) => setValue('insurance_carrier', e.target.value )}
                            />
                        )}
                    />
                </div>            
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Insurance ID"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setValue('insurance_id', e.target.value )}
                    />
                </div>            
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Insurance Group ID"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <GroupIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setValue('insurance_member_id', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Insurance Subscriber ID"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <BadgeIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setValue('insurance_subscriber_id', e.target.value )}
                    />
                </div>
                <h3 style={{ textAlign: "center" }}>Upload Insurance Card</h3>
                <Grid container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={6} p={1} direction="column" container justifyContent="center" alignItems="center">
                        <Grid direction="column" container justifyContent="center" alignItems="center">
                            <PanoramaIcon style={{ fontSize: "10em", opacity: haveCardF === true ? "0.9" : "0.5" }}/>
                            {/* <Check style={{ fontSize: "4em", position: "relative", bottom: 0, left: "-50px", color: "#45bf22" }}/> */}
                        </Grid>
                        <Grid>
                            <Button
                                variant="contained"
                                component="label"
                            >
                                Insurance Card (Front)
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => handleInsuranceCard("f", e.target.files[0])}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} p={1} direction="column" container justifyContent="center" alignItems="center">
                        <Grid ><PanoramaIcon style={{ fontSize: "10em", opacity: haveCardB === true ? "0.9" : "0.5" }}/></Grid>
                        <Grid>
                            <Button
                                variant="contained"
                                component="label"
                            >
                                Insurance Card (Back)
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => handleInsuranceCard("b", e.target.files[0])}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <hr/>
                <h3 style={{ textAlign: "center" }}>Insured Profile</h3>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Full Name of Insured"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        onChange={(e) => setValue('insurance_full_name', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Email of Insured"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setValue('insurance_email', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Address of Insured"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        name="address"
                        onChange={(e) => setValue('insurance_address', e.target.value )}
                    />
                </div>
                <Grid container alignItems="center" direction="row" className={classes.formRow}>
                    <Grid item xs={12} sm={6} p={1}>
                    <TextField
                        label="City"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        name="city"
                        onChange={(e) => setValue('insurance_city', e.target.value )}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} p={1}>
                        <Autocomplete
                            options={stateList}
                            autoHighlight
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="State"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    onChange={(e) => setValue('insurance_state', e.target.value )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <div style={{ padding: "5px" }}>
                    <TextField
                        label="Zip"
                        defaultValue=""
                        variant="filled"
                        fullWidth
                        onChange={(e) => setValue('insurance_zip', e.target.value )}
                    />
                </div>
                <div style={{ padding: "5px" }}>
                    <Autocomplete
                        options={["Self","Spouse","Child","Other"]}
                        autoHighlight
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Relationship to Insured"
                            onChange={(e) => setValue('insurance_relationship', e.target.value )}
                            />
                        )}
                    />
                </div>
            </section>

            <div style={{ maxWidth: "470px", width: "100%", marginTop: "15px" }}>            
                <Button fullWidth variant="contained" color="primary" onClick={() => onSubmit()}>Confirm</Button>
            </div>
        </div>
    )
}

export default ViewPatient
