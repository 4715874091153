import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { Button, Card, CardContent, TextField } from '@mui/material'
import { isValidEmail } from '../../../utilities/helpers'
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';

import Collapse from '@mui/material/Collapse';

const useStyles = makeStyles((theme) => ({
    actions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& button': {
            marginBottom: 20,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
}))

export default function Login(props) {
    const { onChangeView, onSubmit } = props
    const classes = useStyles()
    const [errors, setErrors] = useState({ email: false, password: false })
    const [values, setValues] = useState({ email: '', password: '', showPassword: false, accountType: "" })
    const [isRegister, setRegister] = useState(false);
    const { Application, Authentication } = useSelector((state) => state)
    const { logoSrc } = Application

    const handleChange = (event) => setValues({ ...values, [event.target.name]: event.target.value })

    const handleChangeEmail = (event) => setValues({ ...values, email: event.target.value.toLowerCase().trim() })

    const handleKeyPress = (event) => (event.key === 'Enter' ? validate() : null)

    const validate = () => {
        setErrors({ email: false, password: false })

        if (isValidEmail(values.email) !== true) {
            setErrors({ ...errors, email: 'Must be a valid email' })
            return
        }

        if (!values.password.length) {
            setErrors({ ...errors, password: 'You must enter a password' })
            return
        }

        if (values.accountType === "patient" || values.accountType === "provider") {
            onSubmit(values)
        } else {
            alert("Please choose an account type to login");
            return
        }

    }

    const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
      };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    let accountTypeStyle = { margin: "5px", opacity: "0.4", cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", border: "1px solid #aaa", borderRadius: "15px" };
    let selectedAccount = { ...accountTypeStyle, opacity: "0.8" }
    return (
        <div>
            <Card style={{ textAlign: "center", padding: "25px" }}>
                <img src={logoSrc} style={{ width: "75%", margin: "0 auto" }} className="main-logo main-logo-top" alt="Nexsun Diagnostic Laboratories" />
                <CardContent className={classes.content} sx={{ padding: "0px", paddingTop: "25px" }}>
                    <span>Choose Account Type</span>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", margin: "10px 0" }}>
                        <div 
                            style={ values.accountType === "provider" ? selectedAccount : accountTypeStyle }
                            onClick={ () => setValues({...values, accountType: "provider"}) }
                        >
                            <BadgeIcon style={{ fontSize: "5em" }}/>
                            <span>Provider</span>
                        </div>
                        <div 
                            style={ values.accountType === "patient" ? selectedAccount : accountTypeStyle } 
                            onClick={ () => setValues({...values, accountType: "patient"}) }
                        >
                            <PersonIcon style={{ fontSize: "5em" }}/>
                            <span>Patient</span>
                        </div>
                    </div>
                    <Collapse in={values.accountType === "patient"}>
                        <div style={{ borderLeft: "2px solid grey", width: "90%", padding: "10px", marginBottom: "15px", textAlign: "left" }}>
                            This portal is only accessible for those who have received an email indicating their test results are ready.
                        </div>
                    </Collapse>
                    <FormControl variant="standard">
                        <div style={{textAlign: 'left', marginBlock: "-12px", width: "100%", paddingTop: "20px", display: "flex", alignItems: "center"}}>
                            <EmailIcon style={{ fontSize: "1em" }} />
                            <span style={{paddingLeft: "5px"}}>Email</span>
                        </div>
                        <TextField
                            fullWidth
                            margin="normal"
                            name="email"
                            id="email"
                            type="text"
                            hiddenLabel
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            value={values.email}
                            onChange={handleChangeEmail}
                            onKeyDown={handleKeyPress}
                        />
                    </FormControl>
                    <FormControl variant="standard">
                        <div style={{textAlign: 'left', marginBlock: "-12px", width: "100%", paddingTop: "20px", display: "flex", alignItems: "center" }}>
                            <LockIcon style={{ fontSize: "1em" }} />
                            <span style={{paddingLeft: "5px"}}>Password</span>
                        </div>
                        <TextField
                            fullWidth
                            id="verifyassword"
                            type={values.showPassword ? "text" : "password"}
                            name="password"
                            margin="normal"
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            value={values.password}
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                    </FormControl>
                </CardContent>
                <div style={{ display: "flex", flexDirection: "column", minHeight: "150px", padding: "15px 0", justifyContent: "space-between" }}>
                    <Button style={{ width: "100%" }} variant="contained" color="primary" onClick={() => validate()}>Login</Button>
                    <div style={{ fontSize: "1em", cursor: "pointer" }} onClick={() => onChangeView('newAcct')}>
                        <span>No Account? </span>
                        <span style={{ color: "blue" }} variant="contained" >
                            Register
                        </span>
                    </div>
                    <a style={{ cursor: "pointer" }} variant="contained" onClick={() => onChangeView('forgotPassword')}>
                        Forgot Password?
                    </a>
                </div>
            </Card>
            <Button style={{ width: "100%", textAlign: "center", margin: "15px 0" }}>
                <Link to="/terms" style={{ color: "#fff", textDecoration: "none" }}>Terms and Conditions</Link>
            </Button>
        </div>
    )
}
