import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Switch, Redirect, Route, useHistory } from 'react-router-dom'
import { makeStyles, ThemeProvider  } from '@mui/styles'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ProtectedPage from './components/common/ProtectedPage'
import Loader from './components/common/Loader'
import Snackbar from './components/common/Snackbar'
import ScrollToTop from './components/common/ScrollToTop'
import theme from './theme'

// Views
import Login from './components/views/Login'
import ProviderLogin from './components/views/ProviderLogin'
import Home from './components/views/Home'
import Test from './components/views/Test'
import NewPatientForm from './components/views/Form/NewPatientForm'
import Terms from './components/views/Terms'
import OnSite from './components/views/OnSite'
import Manifest from './components/views/Manifest'
import ProviderUpload from './components/views/ProviderUpload'

const useStyles = makeStyles((theme) => ({
    appWrapper: {
        display: 'flex',
        flex: 0,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const ProtectedRoute = ({ component: Component, authenticated, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (authenticated) {
                    return (
                        <ProtectedPage>
                            <Component {...rest} {...props} />
                        </ProtectedPage>
                    )
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    )
                }
            }}
        />
    )
}

export default function App() {
    const classes = useStyles()
    let history = useHistory()
    const { Authentication } = useSelector((state) => state)
    const { authenticated } = Authentication

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div id="app" className={classes.appWrapper}>
                    <BrowserRouter history={history}>
                        <ScrollToTop>
                            <Switch>
                                <Route exact path="/login" component={Login} />
                                <Route exact path="/provider-login" component={ProviderLogin} />
                                <Route exact path="/new-patient" component={NewPatientForm} />
                                <Route exact path="/terms" component={Terms} />
                                <Route exact path="/upload-providers" authenticated={authenticated} component={ProviderUpload} />
                                <Route exact path="/on-site" component={OnSite} />
                                <Route exact path="/manifest" component={Manifest} />
                                <ProtectedRoute exact path="/tests/:index" authenticated={authenticated} component={Test} />
                                <ProtectedRoute exact path="/" authenticated={authenticated} component={Home} />
                            </Switch>
                        </ScrollToTop>
                    </BrowserRouter>
                    <Loader />
                    <Snackbar />
                    <div>v 1.0.1</div>
                </div>
            </LocalizationProvider>
        </ThemeProvider>
    )
}
